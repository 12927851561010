import ContactLinks from "../ContactLinks/ContactLinks";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";

function Header({ className, darkMode = false }) {
    return (
        <header
            id={styles.header}
            className={[
                className,
                darkMode ? styles.dark_mode : undefined,
            ].join(" ")}
        >
            <Link to={"/"} id={styles.left}>
                <img
                    src={
                        darkMode
                            ? "/images/npsn-header-logo-red.png"
                            : "/images/npsn-header-logo-white.png"
                    }
                    alt="National Peer Support Network logo"
                    id={styles.logo}
                    className={[darkMode ? styles.dark_mode : undefined].join(
                        " "
                    )}
                />
            </Link>
            <ContactLinks className={styles.right} darkMode={darkMode} />
        </header>
    );
}

export default Header;
