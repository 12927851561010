import SupporterLogo from "../SupporterLogo/SupporterLogo";
import styles from "./HeroBanner.module.css";
import {
    supportingAgencies,
    supportingAssociations,
} from "../../data/supporters";

function HeroBanner({ setShowModal }) {
    function handleMoreClick() {
        setShowModal(true);
    }

    return (
        <div className={styles.hero_banner}>
            <div className={[styles.content, "cap-width"].join(" ")}>
                <div id={styles.overline}>
                    <div>Supporting Organizations</div>
                    <div> | </div>
                    <button id={styles.more_button} onClick={handleMoreClick}>
                        More
                    </button>
                </div>
                <div className={styles.supporting_grid}>
                    <div
                        className={[
                            styles.agencies_container,
                            styles.supporting_container,
                        ].join(" ")}
                    >
                        {[...supportingAssociations, ...supportingAgencies].map(
                            ({ name, src }) => (
                                <SupporterLogo
                                    key={name}
                                    name={name}
                                    src={src}
                                />
                            )
                        )}
                    </div>
                    {/* <div
                        className={[
                            styles.sponsors_container,
                            styles.supporting_container,
                        ].join(" ")}
                    > */}
                    {/* <img
                            src="/images/hero-banner/firstnet-logo-white.png"
                            alt="AT&T Firstnet logo"
                            className={styles.sponsor_img}
                        /> */}
                    {/* <img
                            src="/images/hero-banner/firehouse-logo-white.png"
                            alt="Firehouse Subs logo"
                            className={styles.sponsor_img}
                            style={{ width: 100 }}
                        />
                        <img
                            src="/images/hero-banner/cocacola-logo-white.png"
                            alt="Coca Cola logo"
                            className={styles.sponsor_img}
                            style={{ width: 60 }}
                        /> */}
                    {/* <img
                            style={{
                                width: 90,
                                height: 15,
                                }}
                                src="/images/hero-banner/nascar-logo-white.png"
                                alt="NASCAR logo"
                                className={styles.sponsor_img}
                                />
                                <img
                                    style={{
                                        width: 72,
                                        height: 40,
                                    }}
                                    src="/images/hero-banner/smithwesson-logo-white.png"
                                    alt="Smith & Wesson logo"
                                    className={styles.sponsor_img}
                                />
                        <img
                            style={{
                                width: 65,
                                height: 30,
                            }}
                            src="/images/hero-banner/chickfila-logo-white.png"
                            alt="Chick-fil-A logo"
                            className={styles.sponsor_img} 
                        />*/}
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
}

export default HeroBanner;
