import ContactLinks from "../ContactLinks/ContactLinks";
import UnderlinedSpan from "../UnderlinedSpan/UnderlinedSpan";
import styles from "./HeroCarouselPage.module.css";

function HeroCarouselPage({
    children,
    videoSrc,
    headingMaxWidth,
    currentPageIndex,
    specificIndex,
}) {
    return (
        <div className={styles.page}>
            <div className={[styles.content, "cap-width"].join(" ")}>
                <h1
                    style={{ maxWidth: headingMaxWidth }}
                    className={styles.heading}
                >
                    {children}
                </h1>
                <div className={styles.description}>
                    Join agencies across the nation - including Police, Fire,
                    Emergency Medical Services, Corrections, Dispatchers,
                    Veterans, and more - in advocating for a nationwide network
                    for first responders.
                </div>
                <ContactLinks
                    className={styles.links_container}
                    darkMode={false}
                    disableFocus={currentPageIndex !== specificIndex}
                />
            </div>
            <div className={styles.cover}></div>
            <video
                src={videoSrc}
                autoPlay
                muted
                loop
                playsInline
                className={styles.video}
            />
        </div>
    );
}

export default HeroCarouselPage;

export const HeroCarouselPages = [
    (currentPageIndex, specificIndex) => (
        <HeroCarouselPage
            videoSrc={"/videos/satellite-hero-video.mp4"}
            headingMaxWidth={900}
            currentPageIndex={currentPageIndex}
            specificIndex={specificIndex}
        >
            The <UnderlinedSpan underlineType={1}>first</UnderlinedSpan>{" "}
            national peer support network for first responders.
        </HeroCarouselPage>
    ),
    (currentPageIndex, specificIndex) => (
        <HeroCarouselPage
            currentPageIndex={currentPageIndex}
            specificIndex={specificIndex}
            videoSrc={"/videos/paramedics-hero-video.mp4"}
            headingMaxWidth={1095}
        >
            Over <UnderlinedSpan underlineType={1}>36,000</UnderlinedSpan> first
            responder agencies have limited access to support.
        </HeroCarouselPage>
    ),
    (currentPageIndex, specificIndex) => (
        <HeroCarouselPage
            currentPageIndex={currentPageIndex}
            specificIndex={specificIndex}
            videoSrc={"/videos/fireman-hero-video.mp4"}
            headingMaxWidth={970}
        >
            <UnderlinedSpan underlineType={1}>65%</UnderlinedSpan> of
            firefighters are volunteers and can't access support.
        </HeroCarouselPage>
    ),
];
