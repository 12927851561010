import styles from "./Home.module.css";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel";
import "./Home.module.css";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import UnderlinedSpan from "../../components/UnderlinedSpan/UnderlinedSpan";
import IconTile from "../../components/IconTile/IconTile";
import HeroImageCards from "../../components/HeroImageCards/HeroImageCards";
import ArrowAccordion from "../../components/ArrowAccordion/ArrowAccordion";
import { homeFAQ } from "../../data/faq";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import usePageTitle from "../../hooks/usePageTitle";
import ContactLinks from "../../components/ContactLinks/ContactLinks";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import { ourImpact } from "../../data/testimonials";
import HomeModal from "../../components/HomeModal/HomeModal";
import { useRef, useState } from "react";
import useFocusTrap from "../../hooks/useFocusTrap";
import { useEffect } from "react";
import useScrollPostion from "../../hooks/useScrollPosition";

function Home() {
    usePageTitle("Home | National Peer Support Network");
    const introSectionRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    useFocusTrap(modalRef, showModal, { initialFocus: "none" });
    const [hasOpened, setHasOpened] = useState(false);
    const scrollPosition = useScrollPostion();

    useEffect(() => {
        if (
            introSectionRef.current.getBoundingClientRect().bottom -
                window.innerHeight <=
                0 &&
            !hasOpened
        ) {
            setHasOpened(() => {
                setShowModal(true);
                return true;
            });
        }
    }, [scrollPosition, introSectionRef, hasOpened]);

    return (
        <main>
            {showModal && (
                <HomeModal modalRef={modalRef} setShowModal={setShowModal} />
            )}
            <Header className={["cap-width", styles.header].join(" ")} />
            <HeroCarousel />
            <HeroBanner setShowModal={setShowModal} />
            <section
                id={styles.introduction_section}
                data-scroll-tag="learn-more"
                ref={introSectionRef}
            >
                <div
                    id={styles.introduction_section_content}
                    className={"cap-width"}
                >
                    <div id={styles.introduction_text}>
                        <ArrowSubtitle text="Introduction" />
                        <h2 id={styles.introduction_heading}>
                            Advocate for the first{" "}
                            <UnderlinedSpan underlineType={2}>
                                nationwide
                            </UnderlinedSpan>{" "}
                            network for first responders across the U.S.A.
                        </h2>
                        <p className={styles.heading_body}>
                            We are advocating to ensure first responders have
                            access to the support they need most.
                            <br />
                            <br />
                            <strong>
                                By connecting peer supporters across all 50
                                states, we are providing resources that will
                                make a meaningful impact on agencies of all
                                sizes.
                            </strong>
                        </p>
                        <ContactLinks
                            secondaryLink={true}
                            secondaryLabel="Schedule A Call"
                            secondaryHref={"/schedule-a-call"}
                        />
                    </div>
                    <div id={styles.introduction_graphic_container}>
                        <img
                            src="/images/nationwide-network-graphic.png"
                            alt="Graphic of peer supporters digitally connecting across an outline map of the United States."
                            id={styles.introduction_graphic_img}
                        />
                    </div>
                </div>
            </section>
            <section
                id={styles.why_section}
                style={{
                    backgroundImage: "url(/images/police-ems-street.jpg)",
                }}
            >
                <div className={styles.cover}></div>
                <div id={styles.why_section_content} className={"cap-width"}>
                    <div id={styles.why_section_heading}>
                        <ArrowSubtitle
                            text={"Why Join This Network"}
                            darkMode={true}
                        />
                        <h2>
                            This is what we’re
                            <br />
                            <UnderlinedSpan
                                underlineType={2}
                                underlineStyle={{
                                    height: 20,
                                    top: "calc(100% - 15px)",
                                    width: "calc(100% + 16px)",
                                }}
                            >
                                advocating
                            </UnderlinedSpan>{" "}
                            for!
                        </h2>
                    </div>
                    <div id={styles.tiles_container}>
                        <div className={styles.tiles_row}>
                            <IconTile
                                title={"Stronger Peer Support Networks"}
                                description={
                                    "Ensuring first responders can connect with trained peers nationwide."
                                }
                                iconSrc={"/images/support-networks.png"}
                            />
                            <IconTile
                                title={"Access to Critical Resources"}
                                description={
                                    "Providing tools that enhance mental wellness and resilience"
                                }
                                iconSrc={"/images/critical-resources.png"}
                            />
                        </div>
                        <div className={styles.tiles_row}>
                            <IconTile
                                title={"Support for All Agency Sizes"}
                                description={
                                    "Making peer support available to departments, large and small."
                                }
                                iconSrc={"/images/agency-sizes.png"}
                            />
                            <IconTile
                                title={"A Unified National Effort"}
                                description={
                                    "Creating a system that works across state lines to support those who serve."
                                }
                                iconSrc={"/images/national-effort.png"}
                            />
                        </div>
                    </div>
                    <ContactLinks
                        darkMode={false}
                        secondaryLink={true}
                        secondaryLabel="Schedule A Call"
                        secondaryHref={"/schedule-a-call"}
                    />
                </div>
            </section>
            <section id={styles.testimonials_section}>
                <div
                    id={styles.testimonials_section_content}
                    className={"cap-width"}
                >
                    <TestimonialCarousel
                        heading={"What some of your peers are saying."}
                        data={ourImpact}
                        arrowText="Our Impact"
                    />
                </div>
            </section>

            <section id={styles.faq_section}>
                <HeroImageCards className={styles.faq_images_container}>
                    <img
                        className={styles.faq_img}
                        src="/images/faq/faq-1.webp"
                        alt=""
                    />
                    <img
                        className={styles.faq_img}
                        src="/images/faq/faq-2.webp"
                        alt=""
                    />
                    <img
                        className={styles.faq_img}
                        src="/images/faq/faq-3.webp"
                        alt=""
                    />
                    <img
                        className={styles.faq_img}
                        src="/images/faq/faq-4.webp"
                        alt=""
                    />
                </HeroImageCards>
                <div id={styles.faq_section_content} className={"cap-width"}>
                    {" "}
                    <ArrowSubtitle
                        text={"Frequently Asked Questions"}
                        darkMode={true}
                    />
                    <h2 id={styles.faq_heading}>
                        Learn More About The Network
                    </h2>
                    <ArrowAccordion darkMode={true} data={homeFAQ} />
                </div>
            </section>
            <Footer />
        </main>
    );
}

export default Home;
