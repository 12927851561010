export const ourImpact = [
    {
        text: "With thousands of sheriff’s departments across the U.S., the National Peer Support Network will bridge the gap for deputies, corrections officers, and 911 dispatchers—connecting them with peers who truly understand their experiences. By fostering support beyond agency lines, we make it easier for first responders to reach out, ensuring no one has to struggle alone.",
        name: "Jennifer Hull",
        title: "Staff Sergeant",
        org: "Leelanau County Sheriff's Office",
    },
    {
        text: "The National Peer Support Network ensures no first responder stands alone, uniting first responders from agencies of all sizes with wellness support.",
        name: "Chief Robert Stanberry",
        title: "Chief of Police (Ret.)",
        org: "Hudsonville, MI",
    },
    {
        text: "The NPSN initiative transforms first responder wellness, providing peer support and insights—no matter where they serve, with empathy.",
        name: "Chief Daniel Thompson",
        title: "Chief of Police",
        org: "Waukesha County, WI",
    },
];
