import styles from "./IconTile.module.css";

function StatTile({ iconStyle, title, description, iconSrc }) {
    return (
        <div className={styles.icon_tile}>
            <img
                src={iconSrc}
                style={iconStyle}
                alt=""
                className={styles.icon}
            />
            <strong className={styles.title}>{title}</strong>
            <p className={styles.description}>{description}</p>
        </div>
    );
}

export default StatTile;
