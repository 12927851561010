import styles from "./HeroImageCards.module.css";

function HeroImageCards({ children, style, className }) {
    return (
        <div
            style={style}
            className={[className, styles.hero_images_overflow_container].join(
                " "
            )}
        >
            <div className={styles.hero_images}>{children}</div>
        </div>
    );
}

export default HeroImageCards;
