export const homeFAQ = [
    {
        q: "What are you committing to?",
        a: "We are asking for your support in advocating for the network. Please share our mission with colleagues and friends and support the initiative.",
    },
    {
        q: "What does it cost?",
        a: "Joining the network is completely free! Our costs are completely covered by our sponsors.",
    },
    {
        q: "What are the next steps?",
        a: "After filling out the form, we will immediately follow up with additional information requesting details for your agency, and our program representative will reach out to you personally. Also, keep an eye out for monthly newsletters with more news and updates on the network.",
    },
    // {
    //     q: "Can I connect with multiple peer supporters/peers?",
    //     a: "Yes! Our network is designed to match peer supporters and peers based on their desired criteria. For instance, a police officer who is seeking marital advice can connect with a peer supporter who has experience in the subject, while also seeing a different peer supporter for recovery after trauma in the field.",
    // },
    {
        q: "Can someone outside of an agency join?",
        a: "In our first phase, we are primarily recruiting agencies. If you are a veteran, retiree, or civilian, we encourage you to fill out the form and we will be in contact about ways you can support the network.",
    },
];
