import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UnderlinedSpan from "../../components/UnderlinedSpan/UnderlinedSpan";
import usePageTitle from "../../hooks/usePageTitle";
import styles from "./ScheduleCall.module.css";

function ScheduleCall() {
    usePageTitle("Schedule a Call | National Peer Support Network");

    return (
        <main>
            <Header
                className={["cap-width", styles.header].join(" ")}
                darkMode={true}
            />
            <section id={styles.form_section}>
                <div id={styles.form_section_content} className="cap-width">
                    <div id={styles.heading_container}>
                        <h2 aria-level={1} id={styles.heading}>
                            <span style={{ fontWeight: 500 }}>
                                Thank you for your interest in joining the
                                network.
                            </span>{" "}
                            You're making a{" "}
                            <UnderlinedSpan
                                underlineType={2}
                                underlineStyle={{
                                    width: "calc(100% + 6px)",
                                    height: 22,
                                    top: "calc(100% - 16px",
                                }}
                            >
                                difference!
                            </UnderlinedSpan>
                        </h2>
                        <p className={styles.heading_body}>
                            Please select a time that works best to meet with
                            our representatives. We look forward to meeting with
                            you!
                        </p>
                    </div>
                    <iframe
                        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1OLazOiGio2KCI2KFwHW8NP0ssPUov1LXIrEnAvv2hrnRYMTL9w7Aj0myGNZxTTaaEPSeJdjmQ?gv=true"
                        style={{ border: 0 }}
                        width="100%"
                        height="1738"
                        frameBorder="0"
                        title="NPSN Call Scheduler"
                    ></iframe>
                </div>
            </section>
            <Footer />
        </main>
    );
}

export default ScheduleCall;
