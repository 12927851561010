import { Link, useNavigate } from "react-router-dom";
import styles from "./ContactLinks.module.css";
import { useRef } from "react";

function ContactLinks({
    className,
    secondaryLink = false,
    darkMode = true,
    secondaryAction,
    secondaryHref,
    secondaryLabel = "Learn More",
    disableFocus = false,
}) {
    const navigate = useNavigate();
    const mainRef = useRef(null);

    function handleLearnMore() {
        const scrollTarget = document.querySelector(
            '[data-scroll-tag="learn-more"]'
        );

        if (scrollTarget) {
            scrollTarget.scrollIntoView({ behavior: "smooth" });
        } else {
            navigate("/");
        }
    }

    return (
        <div
            className={[className, styles.contact_links].join(" ")}
            ref={mainRef}
        >
            {secondaryLink ? (
                <Link
                    tabIndex={disableFocus ? -1 : 0}
                    className={
                        "link-button outline " + (darkMode ? "" : "dark-mode")
                    }
                    to={secondaryHref}
                >
                    {secondaryLabel}
                </Link>
            ) : (
                <button
                    tabIndex={disableFocus ? -1 : 0}
                    onClick={secondaryAction || handleLearnMore}
                    className={
                        "link-button outline " + (darkMode ? "" : "dark-mode")
                    }
                >
                    {secondaryLabel}
                </button>
            )}
            <Link
                tabIndex={disableFocus ? -1 : 0}
                className="link-button"
                to={"/join"}
            >
                Become an Advocate
            </Link>
        </div>
    );
}

export default ContactLinks;
