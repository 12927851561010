import {
    supportingAgencies,
    supportingAssociations,
} from "../../data/supporters";
import ContactLinks from "../ContactLinks/ContactLinks";
import styles from "./HomeModal.module.css";

function HomeModal({ setShowModal, modalRef }) {
    function handleCloseButtonClick() {
        setShowModal(false);
    }

    function handleBackdropClick(e) {
        if (e.target.id === styles.home_modal_backdrop) {
            setShowModal(false);
        }
    }

    function handleEscape(e) {
        if (e.keyCode === 27) {
            setShowModal(false);
        }
    }

    return (
        <div
            id={styles.home_modal_backdrop}
            onClick={handleBackdropClick}
            onKeyDown={handleEscape}
        >
            <div id={styles.home_modal} className={styles.hide_scrollbar} ref={modalRef}>
                <div id={styles.graphic_container}>
                    <video
                        id={styles.video}
                        src="/videos/home-modal-graphic.mp4"
                        muted
                        autoPlay
                        playsInline
                        loop
                    />
                    <div className={styles.cover}></div>
                </div>
                <div id={styles.content}>
                    <button
                        id={styles.close_button}
                        onClick={handleCloseButtonClick}
                    >
                        <img
                            src="/images/home-modal-close-button.svg"
                            alt="Close home modal"
                        />
                    </button>
                    <div id={styles.heading_container}>
                        <h1>The Network is Growing!</h1>
                        <p>
                            Our advocacy team members have been hard at work and
                            your agency commitment has been very well received.
                        </p>
                    </div>
                    <div id={styles.grid_container}>
                        <div id={styles.cells_container}>
                            <div
                                id={styles.associations_cell}
                                className={styles.cell}
                            >
                                <div className={styles.cell_text_container}>
                                    <strong className={styles.number}>
                                        {supportingAssociations.length}
                                    </strong>
                                    <strong>First Responder</strong>
                                    <strong className={styles.red}>
                                        Assocations
                                    </strong>
                                </div>
                                <div
                                    id={styles.large_logos_container}
                                    className={styles.logos_container}
                                >
                                    {supportingAssociations
                                        .slice(0, 4)
                                        .map(({ name, src }) => (
                                            <img
                                                key={name}
                                                src={src}
                                                alt={name + " logo"}
                                                className={styles.large_logo}
                                            />
                                        ))}
                                </div>
                            </div>
                            <div
                                id={styles.agencies_cell}
                                className={styles.cell}
                            >
                                <div className={styles.cell_text_container}>
                                    <strong className={styles.number}>
                                        {supportingAgencies.length}
                                    </strong>
                                    <strong>First Responder</strong>
                                    <strong className={styles.red}>
                                        Agencies
                                    </strong>
                                </div>
                                <div
                                    id={styles.small_logos_container}
                                    className={styles.logos_container}
                                >
                                    {supportingAgencies
                                        .slice(0, 10)
                                        .map(({ name, src }) => (
                                            <img
                                                key={name}
                                                src={src}
                                                alt={name + " logo"}
                                                className={styles.small_logo}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div id={styles.total_cell_container}>
                            <div id={styles.total_cell} className={styles.cell}>
                                <strong
                                    style={{
                                        fontSize: 36,
                                    }}
                                    className={styles.red}
                                >
                                    <span className={styles.high_asterisk}>
                                        *
                                    </span>
                                    184,514
                                </strong>
                                <strong>Total First Responders</strong>
                            </div>
                            <p id={styles.disclaimer}>
                                <span className={styles.high_asterisk}>*</span>
                                Estimate based on latest agency employment
                                statistics.
                            </p>
                        </div>
                    </div>
                    <ContactLinks
                        className={styles.contact_links}
                        secondaryAction={() => setShowModal(false)}
                    />
                </div>
            </div>
        </div>
    );
}

export default HomeModal;
