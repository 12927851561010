import styles from "./HeroCarousel.module.css";
import { HeroCarouselPages } from "../HeroCarouselPage/HeroCarouselPage";
import { useCallback, useEffect, useRef, useState } from "react";

const HeroCarousel = () => {
    const heroCarouselRef = useRef(null);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const intervalRef = useRef(null);

    function changePage(nextIndex) {
        const nextPage = heroCarouselRef.current.querySelector(
            `[data-index='${nextIndex}'].${styles.carousel_page}`
        );

        const fadingInPage = document.querySelector(
            "." + styles.fading_in_page
        );

        if (fadingInPage) {
            fadingInPage.classList.remove(styles.fading_in_page);
        }
        nextPage.classList.add(styles.fading_in_page);

        heroCarouselRef.current.append(nextPage);
    }

    const resetAutoSlide = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            // setCurrentPageIndex((prev) => {
            //     const nextIndex = (prev + 1) % HeroCarouselPages.length;
            //     changePage(nextIndex);
            //     return nextIndex;
            // });
        }, 10000);
    }, []);

    function handleButtonClick(index) {
        setCurrentPageIndex(() => {
            changePage(index);

            return index;
        });
        // resetAutoSlide();
    }

    // on mount
    useEffect(() => {
        resetAutoSlide();

        return () => clearInterval(intervalRef.current);
    }, [resetAutoSlide]);

    // on page change
    // useEffect(() => {
    //     changePage(currentPageIndex);
    // }, [currentPageIndex]);

    return (
        <div className={styles.hero_carousel} ref={heroCarouselRef}>
            <div className={styles.carousel_page} data-index="1">
                {HeroCarouselPages[1](currentPageIndex, 1)}
            </div>
            <div className={styles.carousel_page} data-index="2">
                {HeroCarouselPages[2](currentPageIndex, 2)}
            </div>
            <div className={styles.carousel_page} data-index="0">
                {HeroCarouselPages[0](currentPageIndex, 0)}
            </div>

            <div className={styles.buttons_container}>
                {HeroCarouselPages.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handleButtonClick(index)}
                        className={[
                            styles.carousel_button,
                            index === currentPageIndex
                                ? styles.active_button
                                : undefined,
                        ].join(" ")}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default HeroCarousel;
