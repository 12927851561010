export const supportingAssociations = [
    {
        src: "/images/hero-banner/cops-logo.png",
        name: "Concerns of Police Survivors (C.O.P.S.)",
    },
    {
        src: "/images/hero-banner/macp-logo.png",
        name: "Michigan Association of Chiefs of Police",
    },
    {
        src: "/images/hero-banner/michigan-sheriff-logo.png",
        name: "Michigan Sheriff's Association",
    },
    {
        src: "/images/hero-banner/ncacp-logo.png",
        name: "North Carolina Association of Chiefs of Police",
    },
    {
        src: "/images/hero-banner/fpca-logo.png",
        name: "Florida Police Chiefs Association",
    },
    {
        src: "/images/hero-banner/scemsa-logo.png",
        name: "South Carolina EMS Association",
    },
];

export const supportingAgencies = [
    {
        src: "/images/hero-banner/wkpd-logo.png",
        name: "Waukesha Police Department",
    },
    {
        src: "/images/hero-banner/wiledr-logo.png",
        name: "LEDR",
    },
    {
        src: "/images/hero-banner/callaway-county-logo.png",
        name: "Callaway County Sheriff's Department",
    },
    {
        src: "/images/hero-banner/durham-county-logo.png",
        name: "Durham County Sheriff's Office",
    },
    {
        src: "/images/hero-banner/maryland-heights-logo.png",
        name: "Maryland Heights Police",
    },
    {
        src: "/images/hero-banner/oxford-logo.png",
        name: "Oxford Police",
    },
    {
        src: "/images/hero-banner/east-lansing-logo.png",
        name: "East Lansing Police Department",
    },
    {
        src: "/images/hero-banner/zeeland-logo.png",
        name: "Zeeland Police Department",
    },
    {
        src: "/images/hero-banner/grandville-logo.png",
        name: "Grandville Police Department",
    },
    {
        src: "/images/hero-banner/newport-beach-logo.png",
        name: "Newport Beach Police Department",
    },
    {
        src: "/images/hero-banner/harris-county-logo.png",
        name: "Harris County Sheriff's Office",
    },
];
