import { useState } from "react";
import styles from "./ArrowAccordion.module.css";
import AccordionArrowSVG from "../SVGs/AccordionArrowSVG";
import StarSVG from "../SVGs/StarSVG";

function ArrowAccordion({ data, darkMode = false }) {
    const [activeRow, setActiveRow] = useState(0);
    return (
        <div className={[styles.arrow_accordion, styles.dark_mode].join(" ")}>
            {data.map((obj, index) => (
                <div
                    key={index}
                    className={[
                        styles.row,
                        index === activeRow ? styles.active : "",
                    ].join(" ")}
                    onClick={() => setActiveRow(index)}
                >
                    <div className={styles.star}>
                        <StarSVG className={styles.star_svg} />
                    </div>
                    <div className={styles.middle}>
                        <div className={styles.question}>{obj.q}</div>
                        <div className={styles.answer}>{obj.a}</div>
                    </div>
                    <button className={styles.right}>
                        <AccordionArrowSVG className={styles.arrow_svg} />
                    </button>
                </div>
            ))}
        </div>
    );
}

export default ArrowAccordion;
