export const headerLinks = [
    // { name: "Home", href: "/" },
    { name: "About The Network", href: "/" },
    { name: "FAQ's", href: "/" },
];

export const footerLinks = {
    Legal: [
        {
            name: "Privacy Policy",
            href: "https://www.keelmind.com/privacy-policy",
        },
        {
            name: "Terms of Use",
            href: "https://www.keelmind.com/terms-of-use",
        },
    ],
    "Contact us": [
        { name: "Join the Network", href: "/join" },
        { name: "Schedule A Call", href: "/schedule-a-call" },
        { name: "connect@npsn.org", href: "mailto:connect@npsn.org" },
        { name: "sponsors@npsn.org", href: "mailto:sponsors@npsn.org" },
    ],
};
