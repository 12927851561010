function AccordionArrowSVG({ className }) {
    return (
        <svg
            className={className}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.00008 1.16666L7.00008 12.8333M7.00008 12.8333L1.16675 6.99999M7.00008 12.8333L12.8334 6.99999"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default AccordionArrowSVG;
