import styles from "./UnderlinedSpan.module.css";

function UnderlinedSpan({
    children,
    underlineType,
    underlineStyle,
    style,
    className,
}) {
    return (
        <span style={style} className={[className, styles.span].join(" ")}>
            <span className={styles.text}>{children}</span>
            <img
                style={underlineStyle}
                className={styles.underline}
                src={"/images/underlines/underline-" + underlineType + ".png"}
                alt=""
            />
        </span>
    );
}

export default UnderlinedSpan;
