import { Link } from "react-router-dom";
import { footerLinks } from "../../data/navigation";
import styles from "./Footer.module.css";

function Footer() {
    return (
        <footer id={styles.footer}>
            <div id={styles.top}>
                <div id={styles.left}>
                    {/* <strong>In support of all first responder agencies.</strong>
                    <div id={styles.logos_container}>
                        <img
                            src="/images/firstnet-logo-blue.png"
                            alt="AT&T Firstnet logo"
                        />
                    </div> */}
                </div>
                <div id={styles.right}>
                    {Object.keys(footerLinks).map((k) => (
                        <div key={k} className={styles.links_column}>
                            <strong id={styles.links_column_heading}>
                                {k}
                            </strong>
                            {footerLinks[k].map(({ name, href }) => (
                                <Link
                                    key={name}
                                    className={styles.link}
                                    to={href}
                                >
                                    {name}
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div id={styles.bottom}>
                <div id={styles.line}></div>
                <div id={styles.copyright}>
                    © Copyright 2025 | All Rights Reserved |{" "}
                    <Link className={styles.link} to={"https://keel.digital/"}>
                        Keel.digital
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
