import styles from "./SupporterLogo.module.css";

function SupporterLogo({ name, src, style }) {
    return (
        <div className={styles.logo_container}>
            <img
                style={style}
                src={src}
                alt={name + " logo"}
                className={styles.logo}
            />
            <div className={styles.tooltip}>{name}</div>
        </div>
    );
}

export default SupporterLogo;
