function Typeform() {
    return (
        <div
            style={{ height: 500 }}
            data-tf-live="01JKBRGAY8STQKSWRYEP8AS3SG"
        ></div>
    );
}

export default Typeform;
