import { useRef } from "react";
import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import styles from "./TestimonialCarousel.module.css";

function TestimonialCarousel({ data, heading, arrowText, darkArrowSubtitle }) {
    const testimonalsContainerRef = useRef(null);

    function handleRight() {
        testimonalsContainerRef.current.classList.add(styles.transition);

        const first = testimonalsContainerRef.current.children[0];

        testimonalsContainerRef.current.style.translate =
            -26 - first.getBoundingClientRect().width + "px";

        setTimeout(() => {
            testimonalsContainerRef.current.classList.remove(styles.transition);

            testimonalsContainerRef.current.style.translate = 0;

            testimonalsContainerRef.current.append(first);
        }, 200);
    }

    function handleLeft() {
        const children = testimonalsContainerRef.current.children;

        const last = children[children.length - 1];

        testimonalsContainerRef.current.prepend(last);
        testimonalsContainerRef.current.style.translate =
            -26 - last.getBoundingClientRect().width + "px";

        setTimeout(() => {
            testimonalsContainerRef.current.classList.add(styles.transition);
            testimonalsContainerRef.current.style.translate = 0;
        }, 0);

        setTimeout(() => {
            testimonalsContainerRef.current.classList.remove(styles.transition);
        }, 200);
    }

    return (
        <div className={styles.testimonial_carousel}>
            <div className={styles.left}>
                <div className={styles.text}>
                    <ArrowSubtitle
                        text={arrowText}
                        darkMode={darkArrowSubtitle}
                    />
                    <h2 className={styles.heading}>{heading}</h2>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={handleLeft}
                        aria-label="Previous testimonial"
                        className={[styles.button, styles.left_button].join(
                            " "
                        )}
                    >
                        <img src="/images/left-arrow-navy.svg" alt="" />
                    </button>
                    <button
                        onClick={handleRight}
                        aria-label="Next testimonial"
                        className={[styles.button, styles.right_button].join(
                            " "
                        )}
                    >
                        <img src="/images/right-arrow-navy.svg" alt="" />
                    </button>
                </div>
            </div>
            <div className={styles.right_overflow_container}>
                <div
                    className={styles.testimonials_container}
                    ref={testimonalsContainerRef}
                >
                    {data.map((t, index) => (
                        <TestimonialCard key={index} {...t} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function TestimonialCard({ text, name, title, width = 360 }) {
    return (
        <div className={styles.testimonial_card} style={{ width }}>
            <div className={styles.card_top}>
                <img src="/images/open-quote.svg" alt="open quote symbol" />
                <p>{text}</p>
            </div>
            <div className={styles.card_bottom}>
                <strong className={styles.card_name}>{name}</strong>
                <div className={styles.card_title}>{title}</div>
            </div>
        </div>
    );
}

export default TestimonialCarousel;
